
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap');


// Color variables

$primary:       #4d79f6;
$secondary:     #4ac7ec;
$success:       #1ecab8;
$info:          #9ba7ca;
$warning:       #f3c74d;
$danger:        #f1646c;
$light:         #f1f5fa;
$white:         #ffffff;
$dark:          #1a253f;
$muted:         #a4abc5;
$pink:          #ff5da0;
$purple:        #7551e9;
$blue:          #2b55cc;


$gray-100:      #f3f6f7;
$gray-200:      #eff2f9;
$gray-300:      #dbe0ec;
$gray-400:      #b6c2e4;
$gray-500:      #8997bd;
$gray-600:      #7081b9;
$gray-700:      #50649c;
$gray-800:      #435177;
$gray-900:      #1c2d41;



// Body Background
$bg-body:                   #eaf0f7;
$body-text:                 #828db1;
$bg-main-wraper:            #ffffff;
$bg-profile:                #1a253f;
$header-title-color:        #1a253f;
$bg-funfact:                #1a253f;


$label:                     #656d9a;
$form-control:              #2f5275;

// Font variables
$font-primary: 'IBM Plex Sans', sans-serif;

// Font Base
$font-size-base:              14px;

// Border
$skill-border:              #ffffff;
$border:                    #d4d8e1;