.developer_main_wrapper {
    max-width: 1140px;
    margin: 65px auto 65px;
    background-color: #ffffff;
    width: 100%;
}

.bg_profile {
    background-color: #1a253f;
}

.section {
    padding: 50px 20px !important;
    position: relative;
}

.section_md {
    padding: 30px 20px !important;
    position: relative;
}

.align_self_center {
    -ms-flex-item-align: center!important;
    align-self: center!important;
}

.profile_main_pic_img {
    max-width: 128px;
    max-height: 128px;
}

.profile_main_pic {
    position: relative;
    max-width: 128px;
    max-height: 128px;
    margin-right: 24px;
}

.profile_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cv_user_name {
    font-size: 24px;
    font-weight: 600;
    color: #b6c2e4;
    margin-bottom: 6px;
}

.cd_headline {
    color: #8997bd;
    font-size: 15px;
    padding-bottom: 5px;
    font-weight: 300;
    line-height: 1.2;
}

.ml_auto {
    margin-left: auto!important;
}

.ml_2 {
    margin-right: 0.5rem!important;
}

.mt_2 {
    margin-top: 0.5rem!important;
}

.mb_3 {
    margin-bottom: 1rem!important;
}

.personal_detail {
    color: #a4abc5;
}

.personal_detail_icon {
    font-size: 20px;
}

.social_icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    color: #ffffff;
}

.bg_blue {
    background-color: #2b55cc !important;
}

.bg_secondary {
    background-color: #4ac7ec !important;
}

.bg_pink {
    background-color: #ff5da0 !important;
}

.header_title {
    margin-bottom: 8px;
    text-transform: capitalize;
    letter-spacing: 0.02em;
    font-size: 17px;
    font-weight: 600;
    margin-top: 0;
    color: #1a253f;
    text-shadow: 0 0 1px rgb(239 242 249 / 10%);
}

.cd_words_wrapper {
    content: '';
    margin-top: 5px;
    position: relative;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background: -webkit-gradient(linear, left top, right top, from(#2b55cc), to(#1ecab8));
    background: linear-gradient(to right, #2b55cc, #1ecab8);
    z-index: 2;
}

.text_primary {
    color: #4d79f6 !important;
}

.font_weight_bold {
    font-weight: 700!important;
}

.h4 {
    line-height: 22px;
    font-size: 18px;
    margin: 10px 0;
}

.p {
    line-height: 1.6;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 0.1px;
    margin-top: 0;
    margin-bottom: 1rem;
}

.personal_detail_title {
    width: 130px;
    float: left;
    font-weight: 500;
    padding-right: 10px;
    position: relative;
    display: block;
}

.personal_detail_separator {
    float: left;
}

.personal_detail_info {
    padding-left: 150px;
    display: block;
}