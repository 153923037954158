html {
  font-size: 62.5%;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-size: 14px;
  background-color: #eaf0f7;
  font-family: ibm-plex-sans, sans-serif;
  color: #50649c;
  letter-spacing: 0.1px;
}

